import React from 'react'
import './Footer.scss'

import { Link } from 'react-router-dom';
import { ReactComponent as SVGLogo } from './svg/visys-logo.svg';
import { ReactComponent as SVGWA } from './svg/whatsapp.svg';
import { ReactComponent as SVGTG } from './svg/telegram.svg';


const Footer = () => {
    return (
        <footer className='footer'>
            <div className='footer__content'>
                <div className='footer__content-navbar-products'>
                    <span className='footer__content-navbar-name'>Продукты</span>
                    <ul>
                        <li>
                            <Link to="/polycamera">
                                Мультифокальная Polycamera
                            </Link>
                        </li>
                        <li>
                            <Link to="omniclient">
                                Видеоплатформа Omni Client
                            </Link>
                        </li>
                        <li>
                            <Link to="canopus">
                                Видеорегистратор Canopus NX
                            </Link>
                        </li>
                        <li>
                            <Link to="norma">
                                Видеодекодер Norma
                            </Link>
                        </li>
                        <li>
                            <Link to="#">
                                Рабочие станции VSS WS
                            </Link>
                        </li>
                        <li>
                            <Link to="#">
                                Виртуализация решений
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className='footer__content-navbar-right'>
                    <div className='footer__content-left-navbar-right-dev'>
                        <span className='footer__content-navbar-name'>Разработка</span>
                        <ul>
                            <li>
                                <a href="#" rel="noopener noreferrer">
                                    Разработка полного цикла
                                </a>
                            </li>
                            <li>
                                <a href="#" rel="noopener noreferrer">
                                    Разработка веб-приложений
                                </a>
                            </li>
                            <li>
                                <a href="#" rel="noopener noreferrer">
                                    Frontend-разработка
                                </a>
                            </li>
                            <li>
                                <a href="#" rel="noopener noreferrer">
                                    Backend-разработка
                                </a>
                            </li>
                            <li>
                                <a href="#" rel="noopener noreferrer">
                                    Аппаратные решения
                                </a>
                            </li>
                            <li>
                                <a href="#" rel="noopener noreferrer">
                                    Аудит процессов разработки
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className='footer__content-navbar-right-about'>
                        <span className='footer__content-navbar-name'>О компании</span>
                        <ul>
                            <li>
                                <a href="#" rel="noopener noreferrer">
                                    О нас
                                </a>
                            </li>
                            <li>
                                <a href="#" rel="noopener noreferrer">
                                    Стажировка
                                </a>
                            </li>
                            <li>
                                <a href="#" rel="noopener noreferrer">
                                    Контакты
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='footer__content-contacts'>
                    <SVGLogo />
                    <span className='footer__content-contacts-phone'>+7 499 113-42-52</span>
                    <span className='footer__content-contacts-mail'>info@visys.ru</span>
                    <div className='footer__content-contacts-socials'>
                        <a href="#" className='social-whatsapp'><SVGWA /></a>
                        <a href="#" className='social-telegram'><SVGTG /></a>
                    </div>
                </div>
                <div className='footer__content-adress'>
                    <button className=''>Написать письмо</button>
                    <div>
                        <span>105094, Москва</span>
                        <span>Семеновская наб., 2/1, стр.1</span>
                    </div>
                    <div>
                        <span>Часы работы с 10:00 - 19:00</span>
                        <span>Понедельник - Пятница</span>
                    </div>
                </div>
            </div>
            <div className='footer__footer'>
                <span>ООО “Видео Технологии”  ©  2020 — 2025 </span>
                <a href='#'>Политика конфиденциальности</a>
            </div>
        </footer>
    )
}

export default Footer;