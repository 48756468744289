import React from 'react'
import './PolycameraPage.scss'


import IMGPolycameraX8 from './image/polycamera_x8_img.png';
import IMGAiorport from './image/airport-img.png';

import {ReactComponent as SVGRender} from './image/polycamera-render_img.svg';
import {ReactComponent as SVGLenses} from './image/lenses_img.svg';
import {ReactComponent as SVGHdrDay} from './image/hdr-day_img.svg';
import {ReactComponent as SVGHdrTwilight} from './image/hdr-twilight_img.svg';
import {ReactComponent as SVGHdrNight} from './image/hdr-night_img.svg';
import {ReactComponent as SVGOperators} from './image/camera-control-operators_img.svg';
import {ReactComponent as SVGArchive} from './image/archive-video_img.svg';
import {ReactComponent as SVGPolycamera} from './image/polycamera-item_img.svg';
import {ReactComponent as SVGDownload} from './image/download_img.svg';
import {ReactComponent as SVGVideosensor} from './image/videosensor_img.svg';

const PolycameraPage = () => {
    return (
        <div>
            <div className='content__window content__window__main polycamera'>
                <h2>Polycamera <span>X8</span></h2>
                <div className='content__window-desc content__window__main-desc'>
                    <span>Наблюдения за большими площадями из единой точки, с высоким качеством изображения и низкими затратами на инфраструктуру</span>
                </div>
                <div className='content__window-img polycamera-img'>
                    <img
                        src={IMGPolycameraX8}
                        alt="Polycamera X8"
                    />
                </div>
                <button className='content__window-button polycamera-button'>Скачать</button>
                <div className='content__window-button-desc content__window__main-button-desc  polycamera-desc'>
                    <span>Лист данных с характеристиками продукта</span>
                </div>
            </div>

            <div className='content__window polycamera-reasons'>
                <h3 className='polycamera-reasons-title'>Причины выбрать Polycamera</h3>
                <div className='content__window-grid polycamera-reasons-grid'>
                    <div className='content__window-grid-item polycamera-reasons-grid-item'>
                        <div className='content__window-grid-item-name'>Большой охват</div>
                        <span className='content__window-grid-item-desc'>Краткое описание ключевого премущества которое необходимо уместить в три строчки, чтобы не улетело</span>
                    </div>

                    <div className='content__window-grid-item polycamera-reasons-grid-item'>
                        <div className='content__window-grid-item-name'>Высокое разрешение</div>
                        <span className='content__window-grid-item-desc'>Краткое описание ключевого премущества которое необходимо уместить в три строчки, чтобы не улетело</span>
                    </div>

                    <div className='content__window-grid-item polycamera-reasons-grid-item'>
                        <div className='content__window-grid-item-name'>Экономия на инфраструктере</div>
                        <span className='content__window-grid-item-desc'>Краткое описание ключевого премущества которое необходимо уместить в три строчки, чтобы не улетело</span>
                    </div>

                    <div className='content__window-grid-item polycamera-reasons-grid-item'>
                        <div className='content__window-grid-item-name'>Многопользовательность</div>
                        <span className='content__window-grid-item-desc'>Краткое описание ключевого премущества которое необходимо уместить в три строчки, чтобы не улетело</span>
                    </div>

                    <div className='content__window-grid-item polycamera-reasons-grid-item'>
                        <div className='content__window-grid-item-name'>Простота использования</div>
                        <span className='content__window-grid-item-desc'>Краткое описание ключевого премущества которое необходимо уместить в три строчки, чтобы не улетело</span>
                    </div>

                    <div className='content__window-grid-item polycamera-reasons-grid-item'>
                        <div className='content__window-grid-item-name'>Российская разработка</div>
                        <span className='content__window-grid-item-desc'>Краткое описание ключевого премущества которое необходимо уместить в три строчки, чтобы не улетело</span>
                    </div>

                </div>
            </div>

            <div className='content__window polycamera-img-airport'>
                <img src={IMGAiorport} alt=""/>
            </div>

            <div className='content__window content__window__second polycamera-multifocal'>
                <div className='content__window__second-text'>
                    <div
                        className='content__window-name content__window__second-text-name  polycamera-multifocal-text-name'>
                        <span>Мультифокальная система</span>
                    </div>
                    <h2 className='content__window-title content__window__second-text-title polycamera-multifocal-text-title'>
                        Описание мультифокальной системы
                    </h2>
                    <div
                        className='content__window-desc content__window__second-text-desc polycamera-multifocal-text-desc'>
                        <span>При увеличении изображения с PTZ камеры, в архив попадет только видимый кадр, с Polycamera не зависимо от увеличения, в архив попадает вся область наблюдения и доступна возможность увеличить нужную область в архиве записи</span>
                    </div>
                </div>
                <SVGRender/>
            </div>

            <div className='content__window polycamera-videosensor'>
                <div className='content__window-name polycamera-videosensor-name'>
                    <span>Видео сенсор</span>
                </div>
                <h2 className='content__window-title polycamera-videosensor-title'>
                    Высокочувствительные сенсоры
                </h2>
                <div className='content__window-desc polycamera-videosensor-desc'>
                    <span>Камера оснащена самыми современными сенсорами разрешением 4К, имеющие чувствительность превышающую возможности человеческого глаза. Это обеспечивает отличные характеристики наблюдения при слабом освещении </span>
                </div>
                <SVGVideosensor/>
            </div>

            <div className='content__window content__window__second polycamera-lenses'>
                <SVGLenses/>
                <div className='content__window__second-text polycamera-lenses-text'>
                    <div className='content__window-name content__window__second-text-name polycamera-lenses-name'>
                        <span>Оптические линзы</span>
                    </div>
                    <h2 className='content__window-title content__window__second-text-title polycamera-lenses-title'>
                        Оптика высокого разрешения
                    </h2>
                    <div className='content__window-desc content__window__second-text-desc polycamera-lenses-desc'>
                        <span>В отличие от PTZ камер, которыми может управлять один человек
                            одновременно, с помщью Plycamera управление может осуществлять
                            неограниченное количество операторов, не мешая друг другу</span>
                    </div>
                </div>
            </div>

            <div className='content__window polycamera-hdr-technology'>
                <div className='content__window-name polycamera-hdr-technology-name'>
                    <span>Видео сенсор</span>
                </div>
                <h2 className='content__window-title polycamera-hdr-technology-title'>
                    <span style={{color: '#FF6363'}}>Цветное </span>
                    <span style={{color: '#2BE295'}}>изображение </span>
                    <span style={{color: '#3769EC'}}>ночью</span>
                </h2>
                <div className='content__window-desc polycamera-hdr-technology-desc'>
                    <span>Технология обратной подсветки матрицы, высокий динамический диапазон с возможностю включения HDR режима, все это обеспечивает получение цветного изображения в условиях низкого освещения</span>
                </div>
                <div className="polycamera-hdr-technology-img">
                    <div className="polycamera-hdr-technology-img-item">
                        <SVGHdrDay/>
                        <div
                            className='content__window-name polycamera-hdr-technology-name polycamera-hdr-technology-img-name'>
                            <span>День</span>
                        </div>
                    </div>
                    <div className="polycamera-hdr-technology-img-item">
                        <SVGHdrTwilight/>
                        <div
                            className='content__window-name polycamera-hdr-technology-name polycamera-hdr-technology-img-name'>
                            <span>Сумерки</span>
                        </div>
                    </div>
                    <div className="polycamera-hdr-technology-img-item">
                        <SVGHdrNight/>
                        <div
                            className='content__window-name polycamera-hdr-technology-name polycamera-hdr-technology-img-name'>
                            <span>Ночь</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='content__window content__window__second polycamera-camera-control'>
                <SVGOperators/>
                <div className='content__window__second-text polycamera-camera-control-text'>
                    <div
                        className='content__window-name content__window__second-text-name polycamera-camera-control-text-name'>
                        <span>Управление камерой</span>
                    </div>
                    <h2 className='content__window-title content__window__second-text-title polycamera-camera-control-text-title'>
                        Неограниченное количество операторов
                    </h2>
                    <div
                        className='content__window-desc content__window__second-text-desc polycamera-camera-control-text-desc'>
                        <span>В отличие от PTZ камеры, которой может управлять один человекв единый момент времени, Plycamera позволяет осуществлятьуправление неограниченнму количеству операторов, не мешая друг другу</span>
                    </div>
                </div>
            </div>

            <div className='content__window content__window__second polycamera-archive-video'>
                <div className='content__window__second-text polycamera-archive-video-text'>
                    <div
                        className='content__window-name content__window__second-text-name polycamera-archive-video-text-name'>
                        <span>Архивное видео</span>
                    </div>
                    <h2 className='content__window-title content__window__second-text-title polycamera-archive-video-text-title'>
                        Увеличение изображения в архиве видеозаписи
                    </h2>
                    <div
                        className='content__window-desc content__window__second-text-desc polycamera-archive-video-text-desc'>
                        <span>При увеличении изображения с PTZ камеры, в архив попадет только видимый кадр, с Polycamera не зависимо от увеличения, в архив попадает вся область наблюдения и доступна возможность увеличить нужную область в архиве записи</span>
                    </div>
                </div>
                <SVGArchive/>
            </div>

            <div className='content__window polycamera-products'>
                <h2 className='content__window-title polycamera-products-title'>
                    Варианты исполнения Polycamera
                </h2>
                <div className='content__window-desc polycamera-products-desc'>
                    <span>Под требования заказчика, мы можем предложить различные
                        варианты исполнения мультифокальной камеры</span>
                </div>
                <div className="polycamera-products-list">
                    <div className="polycamera-products-list-item">
                        <SVGPolycamera/>
                        <div className="polycamera-products-list-item-title">Polycamera <span>Aero</span></div>
                        <div className="polycamera-products-list-item-desc">Наблюдения за взлетно-посадочной полосой и
                            горизонтально протяженных объектов
                        </div>
                        <button>Подробнее</button>
                    </div>
                    <div className="polycamera-products-list-item polycamera-products-list-item-main">
                        <SVGPolycamera/>
                        <div className="polycamera-products-list-item-title">Polycamera <span>Stadium</span></div>
                        <div className="polycamera-products-list-item-desc">Наблюдение за трибунами стадиона и контроль
                            футбольных болельщиков
                        </div>
                        <button>Подробнее</button>
                    </div>
                    <div className="polycamera-products-list-item">
                        <SVGPolycamera/>
                        <div className="polycamera-products-list-item-title">Polycamera <span>Area</span></div>
                        <div className="polycamera-products-list-item-desc">Наблюдения за протяженными улицами и
                            площадями, развязками и автодорогами
                        </div>
                        <button>Подробнее</button>
                    </div>
                </div>
                <div className='polycamera-products-list-details'>
                    Лист данных <a href="/"> Скачать <SVGDownload/></a>
                </div>
            </div>

        </div>
    )
}

export default PolycameraPage